<template>
  <div>
    <div class="login_header">
      <div class="login_header_content">
        <div>
          <img
            src="~@/assets/images/LOGO.png"
            style="width: 188px; height: 45px"
          />
        </div>
        <!-- <div class="select">
          <p style="color: #515a6e; font-size: 16px">地点：</p>
          <div>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // options: [
      //   {
      //     value: "选项1",
      //     label: "中国大陆",
      //   },
      //   {
      //     value: "选项2",
      //     label: "中国香港",
      //   },
      //   {
      //     value: "选项3",
      //     label: "迪拜",
      //   },
      //   {
      //     value: "选项4",
      //     label: "印度",
      //   },
      //   {
      //     value: "选项5",
      //     label: "美国",
      //   },
      //   {
      //     value: "选项6",
      //     label: "南非",
      //   },
      //   {
      //     value: "选项7",
      //     label: "默认",
      //   },
      // ],
      // value: "选项7",
    };
  },
  methods: {
    handleChange(value) {},
  },
};
</script>
<style lang="scss" scoped>
.login_header {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  &_content {
    width: 1540px;
    height: 45px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.select {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
