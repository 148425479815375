<template>
  <div class="footer">
    <div class="footer_content">
      <ul>
        <li>{{ $t("XIMEI") }}</li>
        <li>{{ $t("Website") }}</li>
        <li>{{ $t("Brand Investment") }}</li>
        <li>{{ $t("Product Line") }}</li>
        <li>{{ $t("Mission") }}</li>
        <li>{{ $t("Vision") }}</li>
        <li>{{ $t("Culture") }}</li>
        <li>{{ $t("About Us") }}</li>
        <li>{{ $t("Contact Us") }}</li>
      </ul>
    </div>
    <div class="gw">
      <a href=http://www.xmcpcn.com>
        Copyright 2020 www.xmcpcn.com All rights reserved by
        ximeichengpin. </a>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  text-align: center;
  background-color: #218da0;
  padding: 16px 0;
  color: #fff;
  height: 150px;
   //position: fixed;
   bottom: 0;
  &_content {
    height: 107px;
    padding-top: 20px;
    margin-left: 150px;
  }
  ul {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-bottom: 1px solid #ffffff;
  }
  li {
    width: 140px;
    height: 20px;
    line-height: 1.5;
    font-size: 16px;
    border-right: 1px solid #ffffff;
  }
  .gw {
    margin-top: -10px;
  }
}
</style>
