<template>
  <div class="login-container">
    <el-card class="box-card">
      <div>
        <el-form
          ref="loginForm"
          :model="loginForm"
          class="login-form"
          autocomplete="on"
          label-position="left"
        >
          <div class="title-container">
            <h3 class="title">Welcome to XMVOGUE</h3>
          </div>
          <div class="form-container">
            <el-form-item prop="username">
              <el-input
                ref="username"
                v-model="loginForm.username"
                placeholder="Please enter your username"
                clearable
                type="text"
                size="large"
                tabindex="1"
                autocomplete="on"
              />
            </el-form-item>
          </div>

          <el-tooltip v-model="capsTooltip" placement="right" manual>
            <el-form-item prop="password">
              <el-input
                ref="password"
                v-model="loginForm.password"
                placeholder="Please enter your password"
                size="large"
                clearable
                tabindex="2"
                autocomplete="on"
                show-password
              />
            </el-form-item>
          </el-tooltip>

          <div style="float:right;font-size: 14px;color: #407ee7">
            <a href="javascript:void(0)" style="text-decoration: underline" @click="dialogFindPasswordForm = true ">Forget Password</a>
          </div>
<!--          <el-button
            :loading="loading"
            style=" margin-top: 15px"
            size="primary"
            @click="dialogFindPasswordForm = true">
            Forget Password
          </el-button>-->
          <el-button
            :loading="loading"
            style=" width: 100%;margin-top: 15px"
            size="primary"
            @click="handleLogin">
            Login
          </el-button>
        </el-form>
      </div>
    </el-card>
    <el-dialog
      title="提示"
      :visible.sync="dialogFindPasswordForm"
      width="35%">

      <el-form ref="findform" :model="resetForm" label-width="100px" class="find-form" size="40">
        <el-form-item label="User Name">
          <el-input type="text" placeholder="Please enter your username" class="find-input"
                    v-model="resetForm.username"></el-input>
        </el-form-item>
        <el-form-item label="Bind Email">
          <el-input type="text" placeholder="Please enter your email" class="find-input" v-model="resetForm.email"></el-input>
        </el-form-item>
        <el-form-item label="Verify Code">
          <el-input type="text" placeholder="Please enter verify code" class="find-input"
                    v-model="resetForm.verifycode"></el-input>
          <el-button class="button-fix" @click="getVerifyCode">Get Verify Code</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="dialogFindPasswordForm = false">Cancel</el-button>
          <el-button type="primary" @click="handleFindPassword">Reset</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Login",

  data() {
    return {
      loginForm: {
        username: "",
        password: "" // admin
      },
      capsTooltip: false,
      loading: false,
      dialogFindPasswordForm: false,
      resetForm: {
        username: "",
        email: "",
        verifycode: ""
      }
    };
  },
  created() {
    //this.wLogin()
  },
  methods: {
    handleLogin() {
      if (this.loginForm.username !== "" && this.loginForm.password !== "") {
        this.$axios
          .post("/w1/user/login", this.loginForm)
          .then((res) => {
            this.$store.commit("loginWeb", res.data.data);

            localStorage.setItem("loginWeb", JSON.stringify(res.data.data));

            this.$message.success("Login succeeded"); //登录成功
            this.$router.push("/web");
            document.title = "XMVOGUE";
          })
          .catch((errs) => {
            this.$message.error(errs.response.data.message);
          });
      } else {
        this.$message.warning("Please enter your account password correctly.");
      }
      //this.$router.push("/web");
    },
    getVerifyCode() {

      if (!this.resetForm.username || !this.resetForm.email){
        this.$message.info("Account and Email cannot be empty")
      }

      if (this.resetForm.username && this.resetForm.email) {
        this.$axios.post("/w1/user/getcode", {
            username: this.resetForm.username,
            email: this.resetForm.email
        }).then(() => {

          this.$message.success("send verify code success")

        }).catch(() => {
          this.$message.error("get verify code faild")
        });
      }

    },
    handleFindPassword() {
      if (this.resetForm.email && this.resetForm.verifycode) {
        this.$axios.post("/w1/user/reset", {
            username:this.resetForm.username,
            email:this.resetForm.email,
            verifycode :this.resetForm.verifycode
        }).then(() => {
          this.$message.success("reset password success")
          this.resetForm.email = ''
          this.resetForm.username = ''
          this.resetForm.constructor = ''
          this.dialogFindPasswordForm = false

        }).catch(() => {
          this.$message.error("reset password wrong")
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  width: 100%;
  height: 660px;
  background-image: url("~@/assets/images/web_login_back.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  margin: 0 0 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .login-form {
    position: relative;
    width: 360px;
    max-width: 100%;
    padding: 50px 35px;
    overflow: hidden;
  }

  .form-container {
    margin-bottom: 40px;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: #515a6e;
      margin: 0px auto 20px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .el-button {
    color: #fff;
    background-color: #218da0;
    border-color: #218da0;
  }

  .box-card {
    position: absolute;
    right: 0;
    width: 400px;
    height: 300px;
    background: #fff;
    margin-right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .find-form {
    .find-input {
      width: 60%;

      input {
        height: 40px;
        line-height: 40px;
      }
    }

    .button-fix {
      margin-left: 15px;
    }
  }

}
</style>
