<template>
  <div>
    <div>
      <Header />
    </div>
    <div>
      <Middle />
    </div>
    <div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "./header";
import Middle from "./middle";
import Footer from "../layout/Footer";

export default {
  components: {
    Header,
    Footer,
    Middle,
  },
  data: function () {
    return {};
  },
};
</script>
